import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import React from "react";
import Slide1 from "../../img/slider_1.webp";
import Slide2 from "../../img/slider_2.webp";
import Slide3 from "../../img/slider_3.webp";
import Slide4 from "../../img/slider_4.webp";
import Slide5 from "../../img/slider_5.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const CarouselMain = () => {
	const { t } = useTranslation();
	return (
		<Carousel indicators={false}>
			<Carousel.Item>
				<h2>
					<b>
						{t("benefits.text5")}
						<br></br>
						{t("benefits.text6")}
						<br></br>
						<br></br>
					</b>
				</h2>
				<LazyLoadImage
					src={Slide5}
					className="img-fluid"
					width="957"
					height="798"
					alt="Image Alt"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<h2>
					<b>
						{t("benefits.text1")}
						<br></br>
						<br></br>
					</b>
				</h2>
				<LazyLoadImage
					src={Slide1}
					className="img-fluid"
					width="957"
					height="798"
					alt="Image Alt"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<h2>{t("benefits.text2")}</h2>
				<LazyLoadImage
					src={Slide2}
					className="img-fluid"
					width="957"
					height="798"
					alt="Image Alt"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<h2>{t("benefits.text3")}</h2>
				<LazyLoadImage
					src={Slide3}
					className="img-fluid"
					width="957"
					height="798"
					alt="Image Alt"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<h2>{t("benefits.text4")}</h2>
				<LazyLoadImage
					src={Slide4}
					className="img-fluid"
					width="957"
					height="798"
					alt="Image Alt"
				/>
			</Carousel.Item>
		</Carousel>
	);
};

export default CarouselMain;
