import { Container } from "react-bootstrap";
import React from "react";
import CarouselMain from "../Carousel/CarouselMain";

export const ContentRight = () => {
	return (
		<Container className="col-lg-6 content-right">
			<CarouselMain />
		</Container>
	);
};

export default ContentRight;
