import React from "react";
import { useTranslation } from "react-i18next";
import DateCountdown from "react-date-countdown-timer";

function MyTimer() {
	const { t } = useTranslation();
	const years = t("timer.years");
	const months = t("timer.months");
	const days = t("timer.days");
	const hours = t("timer.hours");
	const minutes = t("timer.minutes");
	const seconds = t("timer.seconds");

	return (
		<DateCountdown
			dateTo="November 24, 2022 23:59:59 GMT+03:00"
			locales_plural={[years, months, days, hours, minutes, seconds]}
			locales={[years, months, days, hours, minutes, seconds]}
			numberOfFigures={3}
		/>
	);
}

export default function Timer() {
	const { t } = useTranslation();

	return (
		<div style={{ textAlign: "center" }}>
			<div style={{ fontSize: "25px" }}>
				<span>{t("timer.text")} </span>
				<b>
					<MyTimer></MyTimer>
				</b>
			</div>
		</div>
	);
}
