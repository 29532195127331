import React, { Suspense, useEffect } from "react";

import "react-phone-input-2/lib/bootstrap.css";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";

import ContainerRight from "../Content/ContentRight";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { selectUser } from "../../rootSlice";
import { useHistory } from "react-router-dom";

const renderLoader = () => <div className="loader"></div>;
const Footer = React.lazy(() => import("../Footer/Footer"));
export default () => {
	const { t } = useTranslation();
	const history = useHistory();
	const user = useSelector(selectUser);


	function validSteps() {
		var isvalid = false;

		if (user.phone === "") {
			isvalid = false;
		} else {
			isvalid = true;
		}
		return isvalid;
	}
	const openInNewTab = (url) => {
		window.open(url, "_blank", "noopener,noreferrer");
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		let isValidStep = validSteps();
		if (isValidStep) {

		} else {
			history.push("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<Container fluid className="full-height">
				<Row className="row-height">
					<Container className="col-lg-6 content-left" id="start">
						<div className="content-left-wrapper">
							<div className="col-lg-12">
								<div className="row" id="logorow">
									<a href="/" id="logo">
										<img
											src="img/logo/logo.svg"
											alt=""
											height="35"
											width="186"
										/>
									</a>
								</div>
							</div>
							<div id="wizard_container">
								<div id="top-wizard">
									<p>
										<b>2/2</b>
									</p>
									<ProgressBar now={100} />
									<form>
										<div className="kayit">
											<img
												className="logo floatLeft"
												src="../../../img/1.webp"
												width={50}
												height={50}
												alt="Logo"
											/>
											<img
												className="logo floatRight"
												id="reverse"
												src="../../../img/1.webp"
												width={50}
												height={50}
												alt="Logo"
											/>
											<h2 className="logoHeader">{t("form.success")}</h2>

											<p>{t("form.successText")}</p>
											<p>{t("form.successText2")}</p>
											<button
												className="form-control btn btn-primary"
												type="button"
												onClick={(e) => {
													e.preventDefault();
													history.push("/");
													openInNewTab("http://bit.ly/PrGnDr");
												}}
											>
												{t("form.download")}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Container>
					<ContainerRight />
				</Row>
			</Container>
			<Suspense fallback={renderLoader()}>
				<Footer />
			</Suspense>
		</div>
	);
};
