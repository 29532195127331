export const TURKISH = {
	page: {
		title: "ParaGönder’e ücretsiz kaydol",
		description: [
			"İŞBANK güvencesiyle Almanya ve Avusturya’dan Türkiye’ye kolayca para gönder.",
		],
	},
	form: {
		label: "Lütfen bilgilerini gir",
		phone: {
			placeholder: "Telefon numarası",
			error: "Lütfen geçerli bir telefon numarası girin",
			submit: "Gönder",
		},
		password: {
			placeholder: "Şifreni Belirle",
			error: "Lütfen geçerli bir şifre girin(6 haneli ve sayısal)",
			submit: "Devam",
		},
		"re-password": {
			placeholder: "Şifreyi yeniden gir",
			error: "Şifreleriniz uyuşmuyor",
			submit: "Devam",
		},
		sms: {
			label: "Son bir adım kaldı, devam et!",
			text: "Sana bir kısa mesaj gönderdik! Lütfen telefonuna gelen doğrulama kodunu gir:",
			resendtext: "SMS kodu gelmedi mi?",
			placeholder: "SMS kodu",
			verify: "Doğrula",
			resend: "Yeniden gönder",
			errMsg:
				"SMS kodu hatalı veya süresi dolmuş olabilir. Lütfen tekrar deneyin.",

			succResendMsg: "SMS kodu başarıyla gönderildi.",
		},
		user: {
			errMsgTitle: "Bu telefon numarasıyla kayıtlı bir kullanıcı bulunuyor.",
			errMsgInfo: "ParaGönder uygulamasına giriş yapmak için: ",
			errMsgButton: "ParaGönder'i İndir!",
		},
		terms: {
			text1: "Lütfen",
			text2: "Hüküm ve Koşullarımız",
			text3: "'ı kabul et.",
		},
		privacy: {
			text1: "Gizlilik Politikası",
			text2: "",
			text5: "nı",
			text3: "kabul ediyorum.",
		},
		name: "Adın",
		surname: "Soyadın",
		email: "E-mail Adresin",
		referral: "Referans",
		campaign: "Kampanya",
		pleaceSelectCampaign: "Lütfen kampanyanızı seçiniz",
		create: "Kaydını tamamla!",
		success: "Tebrikler, üyeliğin tamamlandı!",
		successText:
			"Telefon numarana gelen SMS’deki link ile ParaGönder’i kolayca indirebilirsin. ",
		successText2:
			"Şu an cep telefonunda mısın? Aşağıdaki linke tıkla ve ParaGönder’i hemen kullanmaya başla!",
		download: "ParaGönder`i İndir!",
	},
	request: {
		errMsgTitle: "Hata!",
		errMsgInfo: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
	},
	benefits: {
		text1: " ParaGönder’de ilk Türkiye havalen ücretsiz! ",

		text2:
			"Şubeye gitmene gerek kalmadan dilediğin yerden, dilediğin zaman işlem yap.",
		text3:
			"Almanya ve Avusturya'daki banka hesaplarından Türkiye’de istediğin bankaya para gönder.",
		text4: "Transferlerini İŞBANK güvencesiyle tamamla, için hep rahat olsun.",
		text5: "iPhone 14 Kazanma Şansı!",
		text6: "'Kasım Çekilişi' ParaGönder’de seni bekliyor.",
	},
	headline: {
		text1: "Şimdi ParaGönder’e Kaydol",
		text2: "Türkiye'ye yapacağın para transferlerini İŞBANK güvencesiyle,",
		text3: "Dilediğin yerden, dilediğin zaman gerçekleştir.",
		text4: "Üstelik ilk işlemin",
		text5: " ücretsiz!",
		text6: "",
		text7: "",
	},
	footer: {
		faq: "Sıkça Sorulan Sorular",
		announcement: "Duyurular",
		contact: "İletişim",
		invite: "Arkadaşlarını Davet Et",
		imprint: "Künye",
		terms: "Kullanım Koşulları",
		privacy: "Veri Koruma Politikası",
		termsapp: "ParaGönder Uygulamasının Şart ve Koşulları",
	},
	error: {
		match: "Şifreler eşleşmiyor!",
		empty: "Şifre boş bırakılama!z",
		min: "Şifre 6 rakamdan oluşmalıdır!",
		emptySMS: "SMS boş bırakılamaz!",
		minSMS: "SMS 6 rakamdan oluşmalıdır!",
		phoneEmpty: "Telefon Boş Bırakılamaz!",
		phoneErr: "Geçerli bir telefon numarası girilmelidir",
	},
	timer: {
		text: "İlk işlem ücretsiz !",
		years: "yıl",
		months: "ay",
		days: "gün",
		hours: "saat",
		minutes: "dakika",
		seconds: "saniye",
	},
	cookie: {
		text: {
			small:
				"Sitemizi geliştirmek ve size daha iyi hizmet sunabilmek için çerezler kulanmaktayız. Sitemizi kullanmaya devam ettiğinizde çerez kullanımı da onaylamış olacaksınız. Daha fazla bilgi için Veri koruma Politikamızı inceleyebilirsiniz",
			more: "  Daha fazla bilgi için tıklayın.",
		},
		button: {
			accept: "Kabul Et",
			decline: "Vazgeç",
		},
	},
};
