import "./css/App.css";
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Step1 from "./components/HookForm/Step1";
/* import Step2 from "./components/HookForm/Step2";
 */ import Result from "./components/HookForm/Result";
import React from "react";
import "./locales/i18n";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";


import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);


function App({ signOut, user }) {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Step1} />
				{/* <Route path="/step2" component={Step2} /> */}
				<Route path="/result" component={Result} />
				<Redirect to="/" />
			</Switch>
		</Router>
	);
}
export default withAuthenticator(App, { hideSignUp: true });
