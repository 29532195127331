import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";

import { ENGLISH } from "./en/translations";
import { GERMAN } from "./de/translations";
import { TURKISH } from "./tr/translations";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: ENGLISH,
			},
			de: {
				translation: GERMAN,
			},
			tr: {
				translation: TURKISH,
			},
		},
		interpolation: {
			format: function (value, format, lng) {
				if (value instanceof Date) return moment(value).format(format);
				return value;
			},
		},
	});

export default i18n;
