export const ENGLISH = {
	page: {
		title: "Sign up to ParaGönder for free",
		description: [
			"Transfer money easily from Germany and Austria to Turkey with the security of İŞBANK.",
		],
	},
	form: {
		label: "Please enter your details",
		phone: {
			placeholder: "Phone number",
			error: "Please enter a valid phone number",
			submit: "Send",
		},
		password: {
			placeholder: "Create Your Password",
			error: "Please enter a valid password",
			submit: "Next",
		},
		"re-password": {
			placeholder: "Re-enter password",
			error: "Please enter a valid password",
			submit: "Next",
		},
		sms: {
			label: "One last step, keep going!",
			text: "We sent you an SMS! Please enter the verification code you received on your phone:",
			resendtext: "Didn’t receive SMS code?",
			placeholder: "SMS code",
			verify: "Verify",
			resend: "Resend code",
			errMsg: "The SMS code is incorrect or has expired. Please try again.",
			succResendMsg: "SMS code has been resent.",
		},
		user: {
			errMsgTitle: "There is already a user registered with this phone number.",
			errMsgInfo: "To log in to the ParaGönder app:",
			errMsgButton: "Download ParaGönder!",
		},
		terms: {
			text1: "Please accept our",
			text2: "Terms and Conditions",
			text3: ".",
		},
		privacy: {
			text1: "Privacy Policy.",
			text2: "I agree to the",
			text5: "",
			text3: "",
		},
		name: "Name",
		surname: "Surname",
		email: "Email",
		referral: "Referral",
		campaign: "Campaign",
		pleaceSelectCampaign: "Please select campaign",
		create: "Complete your registration!",
		success: "Congratulations, your registration is complete! ",
		successText:
			"You can easily download ParaGönder with the link in the SMS sent to your phone number.",
		successText2:
			"Are you on your mobile phone? Click on the link below and start using ParaGönder right away!",
		download: "Download ParaGönder!",
	},
	request: {
		errMsgTitle: "Error!",
		errMsgInfo: "An error occurred. Please try again later.",
	},
	benefits: {
		text1: "Your first transfer to Turkey is free with ParaGönder!",

		text2:
			"No need to visit a branch; make transactions from anywhere, at any time.",
		text3:
			"Send money from your bank accounts in Germany and Austria to any bank in Turkey.",
		text4:
			"Complete your transactions with İŞBANK's security and always rest assured.",
		text5: "Chances to win an iPhone 14!",
		text6: "The 'November Giveaway' is waiting for you at ParaGönder.",
	},
	headline: {
		text1: "Sign up to ParaGönder Now",
		text2: "Transfer money to Turkey with the İŞBANK security,  ",
		text3: "from anywhere and anytime.",
		text5: " free!",
		text6: "",
		text7: "",
		text4: "Plus your first transaction is ",
	},
	footer: {
		faq: "Frequently Asked Questions",
		announcement: "Announcement",
		contact: "Contact",
		invite: "Invite Your Friends",
		imprint: "Imprint",
		terms: "Terms and Conditions of Use",
		privacy: "Data Privacy Policy",
		termsapp: "Terms and Conditions of the ParaGönder Application",
	},
	error: {
		match: "Passwords do not match!",
		empty: "Pasword can't be empty!",
		min: "The password must be a 6-digit number!",
		emptySMS: "SMS can't be empty!",
		minSMS: "SMS must be a 6-digit number!",
		phoneEmpty: "Phone cannot be left blank!",
		phoneErr: "Please enter a valid phone number",
	},
	timer: {
		text: "First transaction is free!",
		years: "year",
		months: "month",
		days: "day",
		hours: "hour",
		minutes: "minute",
		seconds: "second",
	},
	cookie: {
		text: {
			small:
				"We use cookies to optimize our website for you and to be able to improve it continuously. By continuing to use the website, you agree to the use of cookies. For more information about cookies, please see our privacy policy.",
			more: "  Click here for more detail.",
		},
		button: {
			accept: "Accept",
			decline: "Decline",
		},
	},
};
