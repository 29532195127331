export const GERMAN = {
	page: {
		title: "Registriere dich kostenlos bei ParaGönder",
		description: [
			"Überweise ganz einfach Geld von Deutschland und Österreich in die Türkei mit der Sicherheit der İŞBANK.",
		],
	},
	form: {
		label: "Bitte gib deine Daten ein",
		phone: {
			placeholder: "Telefonnummer",
			error: "Bitte gib eine gültige Telefonnummer ein",
			submit: "Senden",
		},
		password: {
			placeholder: "Erstelle dein Passwort",
			error: "Bitte gib ein gültiges Passwort ein",
			submit: "Weiter",
		},
		"re-password": {
			placeholder: "Passwort erneut eingeben",
			error: "Passwörter stimmen nicht überein",
			submit: "Weiter",
		},
		sms: {
			label: "Nur noch ein Schritt, los geht’s!",
			text: "Wir haben dir eine SMS geschickt! Bitte gib den Bestätigungscode ein, den du auf deinem Handy erhalten hast:",
			resendtext: "SMS-Code nicht erhalten?",
			placeholder: "SMS code",
			verify: "Überprüfen",
			resend: "Neuer code",
			errTitle: "SMS-Code-Fehler",
			errMsg:
				"Der SMS-Code ist falsch oder abgelaufen. Bitte versuche es erneut.",
			succResendMsg: "SMS-Code wurde erneut gesendet.",
		},
		user: {
			errMsgTitle: "Mit dieser Rufnummer ist bereits ein Benutzer registriert.",
			errMsgInfo: "Um dich bei ParaGönder einzuloggen:",
			errMsgButton: "ParaGönder Herunterladen!",
		},
		terms: {
			text1: "Bitte akzeptiere unsere",
			text2: "unsere AGBs",
			text3: ".",
		},
		privacy: {
			text1: "Datenschutzerklärung",
			text2: "Ich stimme der",
			text5: "",
			text3: "zu.",
		},
		name: "Name",
		surname: "Nachname",
		email: "E-Mail",
		referral: "Verweisung",
		campaign: "Kampagne",
		pleaceSelectCampaign: "Bitte wählen Sie Kampagne",
		create: "Schließe deine Registrierung ab!",
		success: "Herzlichen Glückwunsch, deine Registrierung ist abgeschlossen!",
		successText:
			"Du kannst ParaGönder ganz einfach über den Link in der SMS herunterladen, die an deine Telefonnummer geschickt wurde.",
		successText2:
			"Bist du am Handy? Klicke auf den nachfolgenden Link und nutze ParaGönder sofort!",
		download: "ParaGönder herunterladen!",
	},
	request: {
		errMsgTitle: "Fehler",
		errMsgInfo:
			"Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
	},
	benefits: {
		text1:
			"Mit ParaGönder ist deine erste Überweisung in die Türkei kostenlos!",

		text2:
			"Tätige Überweisungen von überall und zu jeder Zeit, ohne eine Filiale aufzusuchen.",
		text3:
			"Überweise Geld von deinen Konten in Deutschland und Österreich an jede Bank in der Türkei.",
		text4:
			"Schließe deine Überweisungen mit der İŞBANK-Sicherheit ab und bleibe immer auf der sicheren Seite.",
		text5: "Gewinnchance auf ein iPhone 14!",
		text6: "Das 'November Gewinnspiel' wartet auf dich bei ParaGönder.",
	},
	headline: {
		text1: "Jetzt bei ParaGönder registrieren",
		text2: "Überweise Geld mit der İŞBANK-Sicherheit in die Türkei",
		text3: "von überall und jederzeit.",
		text5: "  kostenlos!",
		text6: "",
		text7: "",
		text4: "Zudem ist deine erste Überweisung",
	},
	footer: {
		faq: "Häufig gestellte Fragen",
		announcement: "Ankündigung",
		contact: "Kontakt",
		invite: "Lade deine Freunde ein",
		imprint: "Impressum",
		terms: "Nutzungsbedingungen",
		privacy: "Datenschutzrichtlinie",
		termsapp: "AGB der ParaGönder Applikation",
	},
	error: {
		match: "Passwörter stimmen nicht überein!",
		empty: "Passwort darf nicht leer sein!",
		min: "Das Passwort muss eine 6-stellige Nummer sein!",
		emptySMS: "SMS darf nicht leer sein!",
		minSMS: "SMS muss eine 6-stellige Nummer sein!",
		phoneEmpty: "Telefon darf nicht leer bleiben!",
		phoneErr: "Bitte gib eine gültige Telefonnummer ein",
	},
	timer: {
		text: "Erste Überweisung kostenlos!",
		years: "jahr",
		months: "monat",
		days: "tage",
		hours: "stunde",
		minutes: "minute",
		seconds: "sekunde",
	},
	cookie: {
		text: {
			small:
				"Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer Datenschutzerklärung.",
			more: "  Mehr erfahren.",
		},
		button: {
			accept: "Ich habe verstandem",
			decline: "Ablehnen.",
		},
	},
};
