import React, { lazy, Suspense, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Timer from "./Timer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Auth } from 'aws-amplify';

import { Helmet } from "react-helmet";
import {
	setPhone,
	setPathParameter,
	setLanguage,
	setOs,
	setRecaptchaToken,
	setUserSubId,
	selectUser,
	setName,
	setSurname,
	setEmail,
	setReferral,
	setCampaign,
	setCookie,
} from "../../rootSlice";

import { Container, Row, Form } from "react-bootstrap";

import ContainerRight from "../Content/ContentRight";
import ProgressBar from "react-bootstrap/ProgressBar";
import ErrMsg from "sweetalert2";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { MoonLoader } from "react-spinners";
import { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
const Modal = lazy(() => import("./Modal"));
const Footer = lazy(() => import("../Footer/Footer"));
const Cookie = lazy(() => import("../Cookie/cookie"));
const renderLoader = () => <div className="loader"></div>;
const baseURL = "https://fieldapi.paragonder.com/field/";


const LoadingSpinner = (props) => {
	const { promiseInProgress } = usePromiseTracker();

	return (
		promiseInProgress && (
			<div className="spinner">
				<MoonLoader color="#0052db" margin={0} size={80} speedMultiplier={1} />
			</div>
		)
	);
};

export default () => {
	const [disable, setDisable] = useState(true);
	const user = useSelector(selectUser);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const errUserMsgTitle = t("form.user.errMsgTitle");
	const errEmptyPhone = t("error.phoneEmpty");
	const errPhoneMsg = t("error.phoneErr");
	const errUserMsgInfo = t("form.user.errMsgInfo");
	const errUserMsgButton = t("form.user.errMsgButton");
	const errReqTitle = t("request.errMsgTitle");
	const errReqInfo = t("request.errMsgInfo");
	const [phoneError, setPhoneError] = useState("");
	const [phoneError2, setPhoneError2] = useState("");

	const [show, setShow] = useState(false);

	const handleLoaded = (_) => {
		window.grecaptcha.ready((_) => {
			window.grecaptcha
				.execute("6Lc6gWsiAAAAAIra2sx8i8SbaECi08Q-35pgoyCO", {
					action: "homepage",
				})
				.then((token) => {
					dispatch(setRecaptchaToken(token));
				});
		});
	};

	async function signOut() {
		try {
			await Auth.signOut();
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	function validUser() {
		console.log(user)
		if (user.phone === "" || user.phone.length < 3) {
			setPhoneError(errEmptyPhone);
			return false;
		}
		if (user.name === "") {
			return false;
		} else if (user.surname === "") {
			return false;
		} else if (user.email === "") {
			return false;
		} else if (user.referral === "") {
			return false;
		} else if (user.campaign === "") {
			return false;
		} else {
			return true;
		}
	}

	function createPostRegister(token) {
		Auth.currentSession().then(data => {
			console.log(data.getIdToken().getJwtToken());
			let token = data.getIdToken().getJwtToken();
			let config = {
				headers: {
					"Authorization" : "Bearer "+token
				}
			}

			window.grecaptcha.ready((_) => {
				window.grecaptcha
					.execute("6Lc6gWsiAAAAAIra2sx8i8SbaECi08Q-35pgoyCO", {
						action: "createPostResend",
					})
					.then((token) => {
						trackPromise(
							axios
								.post(baseURL + "register", {
									pathParameter: user.pathParameter,
									phone: user.phone,
									language: user.language,
									os: user.os,
									name: user.name,
									reCaptchaToken: token,
									surname: user.surname,
									email: user.email,
									referral: user.referral,
									campaign: user.campaign,
									cookieConsent: user.cookieConsent,
									validateStatus: function (status) {
										return status < 500;
									},
								},config)
								.catch(function (error) {
									if (error.response) {
										ErrMsg.fire({
											showCloseButton: true,
											title: errReqTitle,
											text: errReqInfo,
											icon: "error",
										});
									} else if (error.request) {
										ErrMsg.fire({
											showCloseButton: true,
											title: errReqTitle,
											text: errReqInfo,
											icon: "error",
										});
									} else {
										ErrMsg.fire({
											showCloseButton: true,
											title: errReqTitle,
											text: errReqInfo,
											icon: "error",
										});
									}
								})
								.then((response) => {
									if (response.data.status === "UsernameExistsException") {
										ErrMsg.fire({
											showCloseButton: true,
											allowOutsideClick: false,
											title: errUserMsgTitle,
											text: errUserMsgInfo,
											icon: "error",
											confirmButtonColor: "#0d6efd",
											confirmButtonText: errUserMsgButton,
										}).then((result) => {
											if (result.isConfirmed) {
												window.location.href = "http://bit.ly/PrGnDr";
											} else {
												window.location.reload();
											}
										});
										setShow(false);
									} else {
										dispatch(setUserSubId(response.data.userSub));

										setShow(true);
									}
								})
						);
					})
			});
		});


	}

	const onSubmit = (e) => {
		console.log("dasda")
		console.log(Auth.user.token)
		const value = Cookies.get("CookieConsent");
		dispatch(setCookie(value));
		e.preventDefault();
		let isValidUser = validUser();
		console.log(isValidUser)
		console.log(Auth.user.token)
		if (isValidUser) {
			window.grecaptcha.ready((_) => {
				window.grecaptcha
					.execute("6Lc6gWsiAAAAAIra2sx8i8SbaECi08Q-35pgoyCO", {
						action: "step1",
					})
					.then((token) => {
						createPostRegister(token);
					});
			});
		} else {
		}
	};

	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			"https://www.google.com/recaptcha/api.js?render=6Lc6gWsiAAAAAIra2sx8i8SbaECi08Q-35pgoyCO";
		script.addEventListener("load", handleLoaded);
		document.body.appendChild(script);

		dispatch(setReferral(Auth.user.attributes.preferred_username));
		dispatch(setCampaign(user.campaign));
		function UrlLoc() {
			let url = window.location.href;

			let resultUrl = url.replace(
				// eslint-disable-next-line
				/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//,
				""
			);
			dispatch(setPathParameter(resultUrl));
		}

		function Language() {
			dispatch(setLanguage(window.navigator.language));
		}

		function getCookieConsentValue() {
			let cookieConsent = Cookies.get("CookieConsent");

			if (cookieConsent) {
				dispatch(setCookie(cookieConsent));
			}
		}

		function getOS() {
			var userAgent = window.navigator.userAgent,
				platform =
					window.navigator?.userAgentData?.platform ||
					window.navigator.platform,
				macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
				windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
				iosPlatforms = ["iPhone", "iPad", "iPod"],
				os = null;

			if (macosPlatforms.indexOf(platform) !== -1) {
				os = "macos";
			} else if (iosPlatforms.indexOf(platform) !== -1) {
				os = "ios";
			} else if (windowsPlatforms.indexOf(platform) !== -1) {
				os = "windows";
			} else if (/Android/.test(userAgent)) {
				os = "android";
			} else if (/Linux/.test(platform)) {
				os = "linux";
			}

			return dispatch(setOs(os));
		}
		getCookieConsentValue();
		UrlLoc();
		Language();
		getOS(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div>
			<Helmet>
				<title>{t("page.title")}</title>
				<meta name="description" content={t("page.description")} />
			</Helmet>
			<Container fluid className="full-height">
				<img className="signout" onClick={signOut} style={{ width: "30px", height: "30px" }} src="img/signOut.svg" alt="test" />
				<Row className="row-height">
					<Container className="col-lg-6 content-left" id="start">

						<div className="content-left-wrapper">
							<div className="col-lg-12">
								<div className="row" id="logorow">
									<a href="/" id="logo">
										<img
											src="img/logo/logo.svg"
											alt=""
											height="35"
											width="186"
										/>
									</a>
								</div>
							</div>

							<div>
								{
									<Container className="top-wizard-inner" id="asd">
										<div className="benefit">
											<h2>{t("headline.text1")}</h2> <br></br>
											<div className="container">
												<div className="row align-items-center ">
													<div className="col-md-2">
														<img
															src="img/icon/icon1.webp"
															alt=""
															height="70"
															width="70"
														/>
													</div>
													<div className="col-md-10">
														<h5 id="boldie">
															{t("headline.text2")}
															<b id="mavibold">{t("headline.text6")}</b>
															{t("headline.text7")}
														</h5>
													</div>
												</div>
												<div className="row align-items-center">
													<div className="col-md-2">
														<img
															src="img/icon/icon2.webp"
															alt=""
															height="70"
															width="70"
														/>
													</div>
													<div className="col-md-10">
														<h5 id="boldie">{t("headline.text3")}</h5>
													</div>
												</div>
												<div className="row align-items-center">
													<div className="col-md-2">
														<img
															src="img/icon/icon3.webp"
															alt=""
															height="70"
															width="70"
														/>
													</div>
													<div className="col-md-10">
														<h5 id="boldie">
															{t("headline.text4")}
															<b id="mavibold">{t("headline.text5")}</b>
														</h5>
													</div>
												</div>
											</div>
										</div>
									</Container>
								}
								<div id="wizard_container">
									<p className="main_question wizard-header">
										{t("form.label")}
									</p>
									<p>
										<b>1/2</b>
									</p>
									<ProgressBar now={33} />
									<form onSubmit={onSubmit} autoComplete="off">
										<div
											className="g-recaptcha"
											data-sitekey="6Lc6gWsiAAAAAIra2sx8i8SbaECi08Q-35pgoyCO"
											data-size="invisible"
											data-callback="onSubmit"
										></div>

										<PhoneInput
											country={"de"}
											inputProps={{
												name: "phone",
												required: true,
												autoFocus: true,
											}}
											localization={{
												de: "Deutschland",
												at: "Österreich",
												tr: "Türkiye",
											}}
											countryCodeEditable={false}
											preferredCountries={["de", "at", "tr"]}
											isValid={(value, country) => {
												if (
													(country.name === "Turkey") &
													((value.length > 2) & (value.length < 12))
												) {
													setDisable(true);
													setPhoneError("");
													setPhoneError2(errPhoneMsg);
													return true;
												} else if (
													(country.name === "Germany") &
													((value.length > 2) & (value.length < 12))
												) {
													setDisable(true);
													setPhoneError("");
													setPhoneError2(errPhoneMsg);
													return true;
												} else if (
													(country.name === "Austria") &
													((value.length > 2) & (value.length < 13))
												) {
													setDisable(true);
													setPhoneError("");
													setPhoneError2(errPhoneMsg);
													return true;
												} else if (value.length < 2) {
													setPhoneError2("");
													setDisable(true);
													setPhoneError(errEmptyPhone);
													return false;
												} else {
													setPhoneError2("");
													setDisable(false);
													return true;
												}
											}}
											/* 											isValid={(inputNumber, country, countries) => {
												return countries.some((country) => {
													return (
														startsWith(inputNumber, country.dialCode) ||
														startsWith(country.dialCode, inputNumber)
													);
												});
											}} */
											id="phone"
											name="phone"
											onChange={(phone) => {
												dispatch(setPhone(phone));
											}}
										/>
										<p className="text-danger">{phoneError2}</p>
										<p className="text-danger">{phoneError}</p>

										<div className="form-group">
											<input
												type="text"
												name="name"
												className="form-control required"
												placeholder={t("form.name")}
												onChange={(e) => dispatch(setName(e.target.value))}
												required
											/>
										</div>
										<div className="form-group">
											<input
												type="text"
												name="surname"
												className="form-control required"
												placeholder={t("form.surname")}
												onChange={(e) => dispatch(setSurname(e.target.value))}
												required
											/>
										</div>

										<div className="form-group">
											<input
												type="email"
												name="email"
												className="form-control required"
												placeholder={t("form.email")}
												onChange={(e) => dispatch(setEmail(e.target.value))}
												required
											/>
										</div>
										<div className="form-group">
											<input
												disabled="true"
												type="referral"
												name="referral"
												className="form-control required"
												placeholder={t("form.referral")}
												value={Auth.user.attributes.preferred_username}
												required
											/>
										</div>
										<div className="form-group">
											<Form.Select
												onChange={(e) => dispatch(setCampaign(e.target.value))}>
												<option selected value="EUROGIDA1222">EUROGIDA1222</option>
												<option value="ISTMAR101">ISTMAR101</option>
												<option value="BOLUMAR101">BOLUMAR101</option>
											</Form.Select>
										</div>
										<div className="form-group terms">
											<label className="container_check">
												{t("form.privacy.text2")}
												<a
													id="terms"
													target="_blank"
													rel="noopener noreferrer"
													href="https://www.paragonder.com/privacypolicy"
												>
													&nbsp;{t("form.privacy.text1")}
												</a>
												{t("form.privacy.text5")}&nbsp;{t("form.privacy.text3")}
												<input
													type="checkbox"
													name="privacy"
													value="Yes"
													className="required"
													required
												/>
												<span className="checkmark"></span>
											</label>
											<label className="container_check">
												{t("form.terms.text1")}
												<a
													id="terms"
													target="_blank"
													rel="noopener noreferrer"
													href="https://www.paragonder.com/termsandconditionsparagonderapplication"
												>
													&nbsp;{t("form.terms.text2")}
												</a>
												{t("form.terms.text3")}
												<input
													type="checkbox"
													name="terms"
													value="Yes"
													className="required"
													required
													title="Lütfen işaretli yerleri doldurunuz"
												/>
												<span className="checkmark"></span>
											</label>
										</div>

										<button
											className="form-control"
											id="btn-send"
											type="submit"
											disabled={disable}
										>
											{t("form.phone.submit")}
										</button>
									</form>
									<Suspense fallback={renderLoader()}>
										<Modal onClose={() => setShow(false)} show={show} />
									</Suspense>
								</div>
							</div>
							<LoadingSpinner />
						</div>
					</Container>
					<ContainerRight />
				</Row>
			</Container>
			<Suspense fallback={renderLoader()}>
				<div className="cta" id="cta">
					<Timer />
				</div>
				<Cookie />
				<Footer />
			</Suspense>
		</div>

	);
};
