import { createSlice } from "@reduxjs/toolkit";

export const rootSlice = createSlice({
	name: "user",
	initialState: {
		pathParameter: "",
		phone: "",
		password: "",
		repassword: "",
		name: "",
		surname: "",
		email: "",
		referral: "",
		campaign: "",
		smsCode: "",
		reCaptchaToken: "",
		language: "",
		os: "",
		userSubId: "",
		cookieConsent: "",
	},
	reducers: {
		setPhone: (state, action) => {
			state.phone = action.payload;
		},
		setName: (state, action) => {
			state.name = action.payload;
		},
		setSurname: (state, action) => {
			state.surname = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setReferral: (state, action) => {
			state.referral = action.payload;
		},
		setCampaign: (state, action) => {
			state.campaign = action.payload;
		},
		setPassword: (state, action) => {
			state.password = action.payload;
		},
		setRepassword: (state, action) => {
			state.repassword = action.payload;
		},
		setSmsCode: (state, action) => {
			state.smsCode = action.payload;
		},
		setPathParameter: (state, action) => {
			state.pathParameter = action.payload;
		},
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setOs: (state, action) => {
			state.os = action.payload;
		},
		setRecaptchaToken: (state, action) => {
			state.reCaptchaToken = action.payload;
		},
		setUserSubId: (state, action) => {
			state.userSubId = action.payload;
		},
		setCookie: (state, action) => {
			state.cookieConsent = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setPhone,
	setName,
	setSurname,
	setEmail,
	setReferral,
	setCampaign,
	setPassword,
	setRepassword,
	setPathParameter,
	setLanguage,
	setOs,
	setSmsCode,
	setRecaptchaToken,
	setUserSubId,
	setCookie,
} = rootSlice.actions;
export const selectUser = (state) => state.user;
export default rootSlice.reducer;
